import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

let Theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5472d3",
      main: "#29ABE2",
      dark: "#22B573",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#f4f4f4",
      dark: "#c1c1c1",
      contrastText: "#000",
    },
  },
})

Theme = responsiveFontSizes(Theme)

export default Theme
